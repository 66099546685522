<template>
  <div>
    <b-modal
        id="modal-upload-profile1"
        ref="modal-upload-profile"
        ok-only
        :ok-title="$t('Pages.Studio.Button.apply_profile')"
        modal-class="modal-info"
        @ok="applySelectedProfile"
        centered
    >
      <b-alert variant="warning" show v-if="waitUntillDataCome">
        <div class="alert-body">
          <feather-icon icon="InfoIcon" class="mr-50" />
          {{ $t('Pages.Studio.Alert.receiving_data_wait') }}
        </div>
      </b-alert>

      <b-form-group label-for="vue-select">
        <label class="mt-1" for="description">{{ this.$t("Pages.Studio.Label.default_upload_profile") }}:</label>
        <b-form-select v-model="uploadProfileIndex">
          <option v-for="(item, key) in this.allUploadProfiles" :value="key">{{ item.profile_name }}</option>
        </b-form-select>
      </b-form-group>

    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BAlert,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BSpinner
} from 'bootstrap-vue'
import moment from "moment-jalaali";

export default {
  name: "UploadProfile",
  props: ['formData'],
  components: {
    BModal,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner
  },
  directives: {
    'b-modal': BModal,
  },
  data(){
    return {
      waitUntillDataCome: true,
      allUploadProfiles:"",
      uploadProfileIndex:""
    }
  },
  created() {
    this.getAllUploadProfiles();
  },
  methods: {
    getAllUploadProfiles() {
      this.$http
          .get(`/api/channel/studio/${this.$route.params.channel_id}/uploadProfile/list`)
          .then((response) => {
            this.allUploadProfiles = response.data;
            for (let date of this.allUploadProfiles) {
              let parsedDate = moment(date.created_at);
              date.created_at = parsedDate.format('YYYY/MM/DD');
            }
            this.waitUntillDataCome = false;
          });
    },
    applySelectedProfile(){
      this.formData.title = this.allUploadProfiles[this.uploadProfileIndex].title;
      this.formData.description = this.allUploadProfiles[this.uploadProfileIndex].description;
      this.formData.selfDeclaredMadeForKids = this.allUploadProfiles[this.uploadProfileIndex].self_declared_made_for_kids ? true : false;
      this.formData.tags = this.allUploadProfiles[this.uploadProfileIndex].tags.split(",") ;
      this.formData.defaultAudioLanguage.label = this.allUploadProfiles[this.uploadProfileIndex].default_audio_language;
      this.formData.defaultAudioLanguage.value = this.allUploadProfiles[this.uploadProfileIndex].abbreviation;
      this.formData.category.label = this.allUploadProfiles[this.uploadProfileIndex].category_name;
      this.formData.category.value = this.allUploadProfiles[this.uploadProfileIndex].category_id;
      this.formData.privacyStatus = this.allUploadProfiles[this.uploadProfileIndex].privacy_status;
      this.formData.playlistsSelect = {label:this.allUploadProfiles[this.uploadProfileIndex].playlist_title, value:this.allUploadProfiles[this.uploadProfileIndex].playlist};
    }
  }

};
</script>

<style scoped>
.suggestion-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.suggestion-list li {
  border: 1px solid #404656;
  color: #b4b7bd;
  padding: 0.8rem 1rem;
  border-radius: 0.357rem;
  background-color: transparent;
  transition: 0.3s;
  cursor: pointer;
}
.suggestion-list li:hover {
  background-color:#404656;
  transition: 0.3s;
}
</style>