<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-modal id="modal-help" ref="modal-help" hide-footer size="lg" centered :title="$t('Navbar.Title Modal Help')">
      <video width="100%" height="100%" controls>
        <source :src="src_help" type="video/mp4">
      </video>
    </b-modal>


    <modal-upload-video :channel_id="channel_id" :user_id="user_id" />

    <div class="bookmark-wrapper align-items-center d-flex">
      <div class="d-flex justify-content-around align-items-center">
        <div v-if="show_help" class="position-relative" v-b-modal.modal-help>
          <feather-icon icon="HelpCircleIcon" size="28" class="cursor-pointer" />
          <b-badge pill variant="light-info" class="position-absolute" style="top: -3px;">
            {{ $t('Navbar.Title Modal Help') }}
          </b-badge>
        </div>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-button class="mx-1 d-none d-lg-block" variant="primary" size="sm" :to="{name:'MasterCoin'}">{{ $t("Pages.MasterCoin.Button.Buy MasterCoin") }}</b-button>
      <b-link :to="{name:'MasterCoin'}" class="mr-1">
        <div class="d-flex align-items-center"  >
          <p class="user-name font-weight-bolder mb-0" style="padding-left: 5px ; padding-right: 5px;">{{count_coin}}</p>
          <b-img class="" :src="require('@/assets/images/icons/mastercoin_icon.png')" width="32"  />
        </div>
      </b-link>
      <!-- <locale dir="ltr" class="text-right" /> -->
      <Locale/>
      <dark-Toggler class="d-none d-lg-block" />
      <b-nav-item-dropdown class="dropdown-notification" menu-class="dropdown-menu-media"
        right>
        <template #button-content>
          <feather-icon v-bind:badge="notifications.length" badge-classes="bg-danger" class="text-body" icon="BellIcon"
            size="21" />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t("Navbar.Notifications") }}
            </h4>
            <b-badge pill v-if="notifications.length > 0" variant="light-warning">
              {{ notifications.length }} {{ $t("Navbar.Badge New") }}
            </b-badge>
          </div>
        </li>

        <!-- Notifications -->
        <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="scrollable-container media-list scroll-area"
          tagname="li">
          <!-- Account Notification -->
          <template v-if="notifications.length > 0">
            <b-link v-for="notification in notifications" :key="notification.id" :id="notification.id">
              <b-media class="position-relative">
                <p class="media-heading pt-1">
                  <span class="font-weight-bolder" v-if="notification.status == 0"> {{ notification.title }} <b-badge
                      pill variant="light-warning">
                      {{ $t("Navbar.Badge New") }}
                    </b-badge></span>
                  <span class="font-weight-bolder" v-else> {{ notification.title }} {{ $t("Navbar.Badge New") }}</span>
                </p>
                <small class="notification-text">{{
                    notification.subtitle
                }}</small>
                <p class="position-absolute notification-text" style="left: 0px; botton: -15px;">{{  MyDate.getFormatDateTimeFA(notification.created_at) }}</p>
              </b-media>
            </b-link>
          </template>
          <h5 class="text-center mx-auto mt-1" v-else>{{ $t("Navbar.Title.There Are No Notifications") }}</h5>
        </vue-perfect-scrollbar>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer" v-if="notifications.length > 0">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="showAllNotification" block>{{
              $t("Navbar.Show All")
          }}</b-button>
        </li>
      </b-nav-item-dropdown>
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ userProfile.name }}
              </p>
              <span class="user-status">
                {{ $t("Navbar." + userProfile.rule) }}
              </span>
            </div>
            <b-avatar size="40" variant="light-primary" :src="userProfile.avatar" class="badge-minimal"
              badge-variant="success" />
          </template>

          <b-dropdown-item link-class="d-flex align-items-center" @click="profileSetting">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>{{ $t("Navbar.Profile") }}</span>
          </b-dropdown-item>

          <b-dropdown-divider />

          <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>{{ $t("Navbar.Exit") }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
  BMedia,
  BButton,
  BImg,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import MyDate from "@core/components/date/MyDate.vue";
import ModalUploadVideo from "@/views/channel/studio/modal-upload-video.vue";
import { userInfoDao } from "@/daos/userInfo/userInfoDao";


export default {
  data() {
    return {
      MyDate,
      userProfile: {
        name: "",
        email: "",
        avatar: "",
        rule: "User",
      },
      notifications: [],
      notificationsLoaded: false,
      video_helps: [
        {
          name_route: 'channels',
          path_video: 'https://api.master.tube/guide/03-Submit-Channel.mp4' //3
        },
        {
          name_route: 'studioDashboard',
          path_video: 'https://api.master.tube/guide/04-Channel-Check.mp4' //4
        },
        {
          name_route: 'insertVideo',
          path_video: 'https://api.master.tube/guide/05-How-to-Upload.mp4' //5
        },
        {
          name_route: 'reviewCheck',
          path_video: 'https://api.master.tube/guide/06-Analyse-Request.mp4' //6
        },
        {
          name_route: 'city',
          path_video: 'https://api.master.tube/guide/07-CITY.mp4' //7
        },
        {
          name_route: 'Pricing',
          path_video: 'https://api.master.tube/guide/demo-plan.mp4' //7
        },
        {
          name_route: 'dashboard',
          path_video: 'https://api.master.tube/guide/dashboard.mp4' //7
        },
      ],
      show_help: false,
      src_help: null,
      count_coin:0,
      channel_id : null,
      user_id:null
    };
  },
  directives: {
    Ripple,
  },
  watch: {
    $route(to, from) {
      let indexHelp = this.video_helps.findIndex(e => e.name_route == this.$route.name)
      if (indexHelp != -1) {
        this.show_help = true;
        this.src_help = this.video_helps[indexHelp].path_video
      } else {
        this.show_help = false;
        this.src_help = null
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  components: {
    BLink,
    BBadge,
    BMedia,
    BButton,
    VuePerfectScrollbar,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    DarkToggler,
    BImg,
    Locale,
    ModalUploadVideo
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
  created() {
    this.getCountCoin()
    let indexHelp = this.video_helps.findIndex(e => e.name_route == this.$route.name)
    if (indexHelp != -1) {
      this.show_help = true;
      this.src_help = this.video_helps[indexHelp].path_video
    } else {
      this.show_help = false;
      this.src_help = null
    }
    this.getUserProfile();
    this.getUserChatId();
    this.getNotificatios();
    this.$root.$on("open_goftino", (data) => {
      Goftino.open();
    });
  },
  methods: {
    setChatInfo(event) {
      this.getUserChatId();
    },
    setUserChatId(chat_id) {
      const payload = {
        chat_id: chat_id,
      };
      this.$http
        .post(`/api/user/chat/set`, payload)
        .then((resp) => { })
        .catch(() => { });
    },
    getUserChatId() {
      this.$http
        .get("/api/user/chat/get")
        .then((response) => {
          this.userChatId = response.data;
          if (this.userChatId) {
            Goftino.setUserId(this.userChatId);
            this.getUserInfo();
            Goftino.close();
          } else {
            var chat_id = Goftino.getUserId();
            this.setUserChatId(chat_id);
            this.getUserInfo();
            Goftino.close();
          }
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        })
      },
    getUserInfo() {
      this.$http
        .get("/api/user/getUserInfo")
        .then((response) => {
          Goftino.setUser({
            email: response.data.email,
            name: response.data.name,
            phone: response.data.mobile,
            avatar: response.data.avatar,
            forceUpdate: true,
          });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        });
    },
    logout() {
      this.$http
        .get("/api/logout")
        .then((response) => {
          this.$VueCookies.remove("mastertube");
          this.$VueCookies.remove("mastertube", null, "master.tube");
          // This is just for demo Purpose. If user clicks on logout -> redirect
          this.$router.push("/login").catch(() => { });
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        });
    },
    profileSetting() {
      this.$router.push("/account/setting").catch(() => { });
    },
    showAllNotification() {
      this.$router.push("/notification/list").catch(() => { });
    },
   async getUserProfile() {
      const userInfo = await userInfoDao.getUserInfo();
      this.userProfile=userInfo;

    },
    getCountCoin() {
      this.$http
        .get(`/api/dashboard/getCountCoin`)
        .then((response) => {
          this.count_coin = response.data.count_coin;
        });
    },
    getNotificatios() {
      this.$http
        .get("/api/user/getUserUnreadNotification")
        .then((response) => {
          this.notificationsLoaded = true;
          this.notifications = response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$VueCookies.remove("mastertube");
            this.$router.push("/login").catch(() => { });
          }
        });
    },
  },
  mounted() {
    this.$root.$on("updateNotifications", (data) => {
      this.notificationsLoaded = false;
      this.notifications = [];
      this.getNotificatios();
    });
    this.$root.$on("channel_id", (data) => {
      this.channel_id = data
    });
    this.$root.$on("user_id", (data) => {
      this.user_id = data
    });
  },
};
</script>
<style>
.app-content {
  margin-bottom: 80px !important;
}
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import "~@core/scss/vue/libs/vue-slider.scss";
@import '~@core/scss/vue/libs/vue-wizard.scss';

[dir="rtl"] body .content-detached.content-right .content-body {
  margin-right: 0 !important;
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.item-img a {
  width: 100% !important;
}
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>
