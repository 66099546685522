<template>
  <div>
    <b-modal
      id="modal-text-generator"
      ref="modal-text-generator"
      ok-only
      :ok-title="$t('Pages.Studio.Button.Close')"
      modal-class="modal-info"
      centered
      :title="$t('Pages.Studio.Title.gen_auto_txt_for', {text: generateFor})"
    >
      <b-alert
      variant="warning"
      show
      >
      <div class="alert-body">
        <feather-icon
          icon="InfoIcon"
          class="mr-50"
        />
        {{ $t('Pages.Studio.Alert.func_use_num_coin_for', {number:useCoins}) }}
      </div>
      </b-alert>
      <div v-if="totalCoins > useCoins">
        <div v-if="suggestions.length === 0">
          <b-form @submit.prevent>
            <b-form-group>
              <label for="inputTextGenerator">{{ $t('Pages.Studio.Label.your_text') }}</label>
              <b-form-input
                id="inputTextGenerator"
                v-model="inputText"
                type="text"
                :placeholder="$t('Pages.Studio.Placeholder.insert_txt')"
                autocomplete="off"
              />
              <small class="mt-1 mb-1 d-block">
                {{ $t('Pages.Studio.Title.please_insert_txt_for', {text:generateFor}) }}
              </small>
            </b-form-group>
          </b-form>
          <b-alert
          variant="info"
          :show="gettingSuggestions"
          >
          <div class="alert-body">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            {{ $t('Pages.Studio.Alert.wait_until_get') }}
            <b-spinner size="md"></b-spinner>
          </div>
          </b-alert>
          <b-button
          class="mb-1"
          variant="success"
          :disabled="gettingSuggestions"
          @click="getTextSuggestions"
          >
            <b-spinner v-if="gettingSuggestions" small></b-spinner>
            <span class="d-block">
              {{ gettingSuggestions ? $t('Pages.Studio.Button.on_progress') : $t('Pages.Studio.Button.show_txts', {text:generateFor}) }}
            </span>
          </b-button>
        </div>
        <div v-else class="mt-2">
          <p>
            {{ $t('Pages.Studio.Title.for_put_txt_in', {text: generateFor}) }}
            <b-button
            size="sm"
            variant="warning"
            :disabled="resetSuggestion"
            @click="resetTextSuggestions"
            class="mt-1"
            >
              <b-spinner v-if="resetSuggestion" small></b-spinner>
              <span v-else>
                {{ $t('Pages.Studio.Button.new_order') }}
              </span>
            </b-button>
          </p>
          <ul class="suggestion-list">
            <li v-for="(suggest, index) in suggestions" :key="index" @click="selectedTextHandler(suggest)">{{ suggest }}</li>
          </ul>
        </div>
      </div>
      <div v-else>
        <b-alert
        variant="danger"
        show
        >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          {{ $t('Pages.Studio.Alert.your_coin_is_fewer_than', {number:useCoins}) }}
        </div>
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { 
  BModal,
  BAlert,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,

} from 'bootstrap-vue'

export default {
  name: "TextGenerator",
  props:{
    type: String,
  },
  components: {
    BModal,
    BAlert,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
  
  },
  directives: {
    'b-modal': BModal,
  },
  data(){
    return {
      currentType: '',
      totalCoins: 0,
      useCoins: 5,
      canGetText: false,
      inputText: '',
      gettingSuggestions: false,
      suggestions: [],
      resetSuggestion: false
    }
  },
  computed:{
  generateFor(){
    return this.$t(`Pages.Studio.Label.${this.type.charAt(0).toUpperCase() + this.type.slice(1)}`) 
  }
  },
  methods: {
    getTextSuggestions(){
      if (this.totalCoins > this.useCoins){
        this.gettingSuggestions = true
        this.$http.post('/api/channel/studio/get-text-suggestion', {text: this.inputText, type: this.currentType, channel: this.$route.params.channel_id})
        .then(response => {
          if (response.data.status){
            this.suggestions = response.data.data
            this.totalCoins -= this.useCoins
          }else{
            this.gettingSuggestions = false
          }
        })
        .catch(error => {
          this.gettingSuggestions = false
        })
      }else{
        this.$swal({
          icon: 'error',
          title: this.$t('Base.Alert.Error'),
          text: this.$t('Pages.Studio.Alert.your_coin_is_fewer_than', {number:useCoins})
        })
      }
    },
    selectedTextHandler(text){
      document.getElementById(this.currentType).value = text
      this.$nextTick(()=>{
        this.$refs['modal-text-generator'].hide()
      })
    },
    resetTextSuggestions(){
      this.resetSuggestion = true
        this.inputText = ''
        this.gettingSuggestions = false
        this.suggestions = []
        this.resetSuggestion = false
    }
  },
  watch: {
    type(newType, oldType){
      if (newType !== oldType){
        this.currentType = newType
        this.inputText = ''
        this.gettingSuggestions = false
        this.suggestions = []
      }
    }
  },
  mounted(){
    this.$http.get('/api/dashboard/getCountCoin')
    .then(response => {
      this.totalCoins = response.data.count_coin
    })
  }
};
</script>

<style scoped>
.suggestion-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.suggestion-list li {
  border: 1px solid #404656;
  color: #b4b7bd;
  padding: 0.8rem 1rem;
  border-radius: 0.357rem;
  background-color: transparent;
  transition: 0.3s;
  cursor: pointer;
}
.suggestion-list li:hover {
  background-color:#404656;
  transition: 0.3s;
}
</style>