import consoleService from "@/services/consoleService";
import { getUserInfoReq } from "../../requests/userInfo/getUserInfo";
class UserInfoDao {
  constructor() {
    if (!UserInfoDao.instance) {
      this.userInfoCache = {
       userInfo: null,
        refreshFlag: false,
      };
      UserInfoDao.instance = this;
    }
    return UserInfoDao.instance;
  }
  async getUserInfo() {
    try {
      if (!this.userInfoCache.userInfo || this.userInfoCache.refreshFlag) {
        const result = await getUserInfoReq();
        this.userInfoCache.userInfo = result;
        this.userInfoCache.refreshFlag = false;
      }
      return this.userInfoCache.userInfo;
    } catch (error) {
      consoleService.error("Error in getting user info", error);
    }
  }
  forceRefreshUserInfoDao() {
    this.userInfoCache.refreshFlag = true;
  }
}

const userInfoDao = new UserInfoDao();
export { userInfoDao };
