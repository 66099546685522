export default [
  {
    title: "Menu.Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    permission: null,
    skipCheckPermission: true,
  },
//  {
//    title: "Menu.Upgrade User Account",
//    route: "Pricing",
//    icon: "StarIcon",
//    permission: null,
//    skipCheckPermission: true,
//  },
  {
    title: "Menu.Channels",
    route: "channels",
    icon: "ListIcon",
    permission: null,
    skipCheckPermission: true,
  },
  // {
  //   title: "Menu.Accounting",
  //   route: "Accounting",
  //   icon: "CreditCardIcon",
  //   tag: "New",
  //   tagVariant: "light-info",
  //   permission: null,
  //   skipCheckPermission: true,
  // },
  {
    title: "Menu.Mastertube City",
    route: "city",
    icon: "GlobeIcon",
    permission: "Show City",
  },
  {
    title: "Menu.MasterFile",
    icon: "FolderIcon",
    route: "masterFile",
    skipCheckPermission: true,
  },
  {
    title: "Menu.Channel Analysis",
    icon: "GlobeIcon",
    route: "assistant-reviews",
    permission: null,
    skipCheckPermission: true,
  },
  {
    title: "Menu.Tools",
    icon: "SettingsIcon",
    tag: "New",
    tagVariant: "light-warning",
    children: [
      {
        title: "Menu.Calculator",
        route: "calculator",
        icon: "CoffeeIcon",
        child: true,
        skipCheckPermission: true,
      },
      {
        title: "Menu.Thumbnail Downloader",
        route: "thumbnaildownloader",
        icon: "DownloadIcon",
        child: true,
        skipCheckPermission: true,
      },
      {
        title: "Menu.Subscribe Link Generator",
        route: "subscribeLinkGenerator",
        icon: "CopyIcon",
        child: true,
        skipCheckPermission: true,
      },
    ],
    permission: null,
    skipCheckPermission: true,
  },
  {
    title: "Menu.Notifications",
    icon: "BellIcon",
    route: "notifications",
    permission: null,
    skipCheckPermission: true,
  },
  {
    title: "Menu.Account",
    icon: "UserIcon",
    route: "setting",
    permission: null,
    skipCheckPermission: true,
  },
  {
    title: "Menu.Faq",
    icon: "MessageCircleIcon",
    route: "support",
    permission: null,
    skipCheckPermission: true,
  },
  {
    title: "Menu.Support",
    icon: "HeadphonesIcon",
    route: "ticket",
    permission: null,
    skipCheckPermission: true,
  },
];
