<template>
    <b-alert show class="full-width mb-2 p-1 mt-4 mt-sm-0 alert-deprecate" :value="true">
        <b-row class="align-items-center">
            <b-col sm="12" :lg="!full?  '6' : '12'">
                <div style="line-height: 25px"><b style="font-weight: 700; font-size: 14px">
                    توجه !
                </b></div>
                <p style="line-height: 25px; font-weight: 400">
                    یوتیوبر عزیز، داشبورد فعلی مسترتیوب تا تاریخ ۲۷ بهمن ۱۴۰۲ در دسترس خواهد بود.
                    برای استفاده از خدمات داشبورد پس از این تاریخ، لطفا به داشبورد جدید وارد شوید و یک حساب کاربری ایجاد
                    کنید.
                </p>
            </b-col>
            <BCol class="justify-content-end d-flex" sm="12" :lg="!full?  '6' : '12'">
                <BButton v-bind="{
                          style:!full ?'padding: 15px 22px':'margin-top:15px'
                          }" class="btn-alert btn-primary" href="//app.master.tube" tag="a">
                    داشبورد جدید
                </BButton>
            </BCol>
        </b-row>
    </b-alert>
</template>
<script>
import {BAlert, BButton, BRow, BCol,} from "bootstrap-vue";

export default {
    props: {
        full: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BAlert,
        BButton,
        BRow,
        BCol
    }
}
</script>
<style>
.alert-deprecate {
    background: rgba(234, 84, 85, 0.12) !important;
    color: rgba(234, 84, 85, 1) !important;
}

.alert-deprecate .btn-alert {
    background: rgba(234, 84, 85, 1) !important;
    border-color: rgba(234, 84, 85, 1) !important;
    color: white !important;
}

</style>
