<template>
  <div v-if="blacklistWords.length > 0">
    <b-alert variant="warning" show>
      <div class="alert-body">
        <span>{{
          $t("Pages.Studio.Alert.black_list_words")
        }}</span>
        <ul>
          <li v-for="(blacklistWord, index) in blacklistWords" :key="index">
            {{ blacklistWord.category }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from "bootstrap-vue";

export default {
  props: ["blacklistWords"],
  components: { BAlert },
};
</script>

<style></style>
