<template>
  <div v-if="suggestions.length > 0" id="keywordSuggestions">
    <ul>
      <li v-for="(suggestion, index) in suggestions" :key="index" @click="changeSelectedHashtag(suggestion)">#{{ suggestion }}</li> 
    </ul>
  </div>
</template>

<script>
export default {
    name: 'KeywordSuggestion',
    props: {
      suggestions: Array,
      changeSelectedHashtag: Function
    },
    mounted(){
      console.log('okkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk');
    }
}
</script>

<style scoped>
ul{
  padding: 0;
  margin-bottom: 0;
}

li {
  background-color: #d5d5d5;
  list-style: none;
  font-size: 14px;
  line-height: 30px;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 3px;
  margin: 2px;
  cursor: pointer;
}
</style>

<style scoped>
#keywordSuggestions {
  position: absolute;
  /* top: 2.6487rem; */
  max-width: 360px;
  max-height: 380px;
  z-index: 222;
  overflow: auto;
  overflow-x: hidden;
  display: block;
  padding: 8px 0;
  background: white;
  border: 1px solid #404656;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
}

#keywordSuggestions li {
  color: #0d0d0d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 0;
  padding: 6px 10px 6px 16px;
  font-size: 15px;
}

#keywordSuggestions li:focus, #keywordSuggestions li:hover {
  background-color: #f1f1f1
}

</style>