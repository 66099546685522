<template>
  <div>
    <text-generator :type="textGeneratorModalType" />
    <upload-profile :formData="formCreate"></upload-profile>
    <b-modal
      id="modal-playlist"
      ref="modal-playlist"
      cancel-variant="outline-secondary"
      :ok-title="formCreatePlaylist.btn"
      :cancel-title="$t('Base.Alert.Cancel')"
      centered
      :title="formCreatePlaylist.titleForm"
      @ok="CreatePlaylist"
    >
      <b-form>
        <b-form-group>
          <label for="title">{{ this.$t("Pages.Studio.Label.Title") }}:</label>
          <div>
            <b-form-input
              maxlength="150"
              id="title"
              type="text"
              v-model="formCreatePlaylist.title"
              required
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 150 /
              {{ formCreatePlaylist.title.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="description"
            >{{ this.$t("Pages.Studio.Label.Description") }}:</label
          >
          <div>
            <b-form-textarea
              maxlength="5000"
              id="description"
              type="text"
              v-model="formCreatePlaylist.description"
              rows="8"
            />
            <small class="mt-2">
              {{ $t("Pages.Studio.Title.Characters") }}: 5000 /
              {{ formCreatePlaylist.description.length }}
            </small>
          </div>
        </b-form-group>
        <b-form-group label-for="vue-select">
          <label class="mt-1" for="description"
            >{{ this.$t("Pages.Studio.Label.Status") }}:</label
          >
          <v-select
            id="vue-select"
            v-model="formCreatePlaylist.privacyStatus"
            :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
            :options="option"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal
      class="mt-5"
      scrollable
      id="modal-upload-video"
      ref="modal-upload-video"
      size="huge"
      :hide-footer="select_video_step"
      no-close-on-backdrop
      centered
      :title="$t('Navbar.upload_vid_modal')"
      @hide="showProssessInPage"
    >
      <div
        v-if="select_video_step"
        @dragover.stop.prevent="onChangeVideo"
        @dragleave.stop.prevent="onChangeVideo"
        @drop.stop.prevent="onChangeVideo"
        style="height: 700px"
        class="d-flex flex-column justify-content-between align-items-center text-center"
      >
        <div
          v-if="file"
          class="text-center"
          style="width: 80%; height: 80%; margin-top: 15%"
        >
          <div class="mb-2">
            <b-img
              class="rounded-lg"
              rounded
              :src="require('@/assets/images/icons/upload-dynamic.gif')"
              height="80"
            />
          </div>
          <h6 class="">
            {{ $t("Pages.Studio.Title.success_file_selected") }}...
          </h6>
        </div>
        <div
          v-else
          class="text-center"
          style="width: 80%; height: 80%; margin-top: 15%"
        >
          <div class="mb-2">
            <b-img
              class="rounded-lg"
              rounded
              :src="uploadStaticIcon"
              height="80"
            />
          </div>
          <h4 class="text-center">{{ $t("Pages.Studio.Title.drag_drop") }}</h4>
          <h6 class="text-muted">
            {{ $t("Pages.Studio.Title.private_until_publish") }}
          </h6>
          <b-button variant="info" class="mt-1" @click="selectFile">{{
            $t("Pages.Studio.Button.select_file")
          }}</b-button>
        </div>
        <div>
          <h6 class="text-muted mt-5">
            {{ $t("Pages.Studio.Title.upload_on_youtube")
            }}<a target="_blank" href="https://master.tube/privacy-policy/">{{
              $t("Pages.Studio.Title.terms_service")
            }}</a>
            {{ $t("Pages.Studio.Title.And") }}
            <a target="_blank" href="https://master.tube/terms-of-service/">{{
              $t("Pages.Studio.Title.forum_guidelines")
            }}</a
            >{{ $t("Pages.Studio.Title.youtube_agree") }}
          </h6>
          <h6 class="text-muted">
            {{
              $t(
                "Pages.Studio.Title.violate_copyright_privacy"
              )
            }}<a target="_blank" href="https://www.youtube.com/yt/copyright">{{
              $t("Pages.Studio.Title.learn_more")
            }}</a>
          </h6>
        </div>
      </div>
      <b-tabs v-model="tabIndex" v-else style="height: 700px">
        <b-tab active>
          <template #title>
            <feather-icon icon="InfoIcon" />
            <span>{{ $t("Pages.Studio.Title.step_one_details") }}</span>
          </template>
          <b-row cols="12" class="mt-2">
            <b-col md="7" sm="12" class="mx-auto order-1 order-md-0">
              <b-form-group class="position-relative">
                <label
                  for="title"
                  style="font-size: 16px"
                  v-b-tooltip.hover.top="
                    $t(
                      'Pages.Studio.Title.length_title_between'
                    )
                  "
                >
                  {{ this.$t("Pages.Studio.Label.Title") }}:
                  <feather-icon
                    :class="seo.title ? 'text-info' : ''"
                    icon="AlertCircleIcon"
                    size="16"
                /></label>
                <b-form-input
                  id="title"
                  maxlength="100"
                  type="text"
                  v-model="formCreate.title"
                  @keyup="checkSeoTitle()"
                  required
                />
                <small class="mt-1">
                  {{ $t("Pages.Studio.Title.Characters") }}: 100 /
                  {{ formCreate.title.length }}
                </small>

                <small v-if="seo.title" class="text-info d-block">
                  {{
                    $t(
                      "Pages.Studio.Title.length_title_between"
                    )
                  }}
                </small>
                <blacklist-word-notification
                  :blacklistWords="titleBlacklistWords"
                />



                <b-button
                  v-b-modal.modal-upload-profile1
                  class="position-absolute text-generator-button"
                  variant="outline-success"
                  size="sm"
                  style="margin-left: 21%; margin-top: 0%"
                >
                  {{ $t("Pages.Studio.Button.default_upload_profile") }}
                </b-button>
              </b-form-group>

              <b-form-group class="position-relative">
                <label
                  for="description"
                  style="font-size: 16px; line-height: 25px"
                  >{{ this.$t("Pages.Studio.Label.Description") }}:
                  <feather-icon
                    :class="seo.tagDescription ? 'text-info mx-1' : 'mx-1'"
                    v-b-tooltip.hover.top="
                      $t(
                        'Pages.Studio.Title.least_one_hash'
                      )
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />
                  <feather-icon
                    :class="seo.socialDescription ? 'text-info mx-1' : 'mx-1'"
                    v-b-tooltip.hover.top="
                      $t(
                        'Pages.Studio.Title.least_one_link'
                      )
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />

                  <feather-icon
                    :class="seo.titleDescription ? 'text-info mx-1' : 'mx-1'"
                    v-b-tooltip.hover.top="
                      $t(
                        'Pages.Studio.Title.should_mention_in_desc'
                      )
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />
                </label>
                <b-form-textarea
                  id="description"
                  maxlength="5000"
                  style="line-height: 25px"
                  type="text"
                  :placeholder="
                    $t('Pages.Studio.Placeholder.tell_viewer')
                  "
                  v-model="formCreate.description"
                  rows="10"
                  @keyup="
                    checkSeoDescription();
                    updateKeywordSuggestionsStyle();
                  "
                />
                <b-button
                  v-b-modal.modal-text-generator
                  class="position-absolute text-generator-button"
                  variant="outline-success"
                  size="sm"
                  @click="
                    () => {
                      textGeneratorModalType = 'description';
                    }
                  "
                >
                  {{ $t("Pages.Studio.Button.auto_txt_gen") }}
                </b-button>
                <keyword-suggestion
                  :suggestions="suggestions"
                  :changeSelectedHashtag="changeSelectedHashtag"
                />
                <small class="mt-1">
                  {{ $t("Pages.Studio.Title.Characters") }}: 5000 /
                  {{ formCreate.description.length }}
                </small>
                <blacklist-word-notification
                  :blacklistWords="descriptionBlacklistWords"
                />
              </b-form-group>
              <h6>
                <label for="thumbnails" style="font-size: 16px"
                  >{{ this.$t("Pages.Studio.Label.thumbnail_video") }}:
                  <feather-icon
                    :class="seo.thumbnails ? 'text-info' : ''"
                    v-b-tooltip.hover.top="
                      $t('Pages.Studio.Title.must_cover')
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />
                </label>
              </h6>
              <p class="text-muted">
                {{
                  $t(
                    "Pages.Studio.Title.choose_img_about"
                  )
                }}<b-link
                  target="_blank"
                  href="https://support.google.com/youtube/answer/72431?hl=en"
                  >{{ $t("Pages.Studio.Title.learn_more") }}</b-link
                >
              </p>
              <b-form-group>
                <div
                  id="thumbnails_prew"
                  style="width: 80px; height: 80px"
                  @dragover.stop.prevent="onChangeThumbnail"
                  @dragleave.stop.prevent="onChangeThumbnail"
                  @drop.stop.prevent="onChangeThumbnail"
                  @click="selectThumbnail"
                >
                  <div v-if="formCreate.thumbnails" width="100%" class="d-flex">
                    <img
                      class="px-1"
                      :src="formCreate.thumbnails_prew"
                      width="130px"
                      height="74"
                      alt=""
                    />
                  </div>
                  <div
                    v-else
                    style="
                      width: 80px;
                      height: 80px;
                      background: #000;
                      width: 100%;
                    "
                    class="d-flex flex-column justify-content-center align-items-center"
                  >
                    <feather-icon
                      class="text-white mx-auto"
                      icon="UploadIcon"
                      size="16"
                    />
                    <P class="mx-auto">{{
                      $t("Pages.Studio.Button.select_file")
                    }}</P>
                  </div>
                </div>
              </b-form-group>
              <b-form-group label-for="vue-playlist">
                <label
                  class="d-flex justify-content-between align-items-center"
                  for="playlist"
                  style="font-size: 16px"
                >
                  <span> {{ this.$t("Pages.Studio.Label.Playlists") }}: </span>
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    v-b-modal.modal-playlist
                    >{{
                      $t("Pages.Studio.Button.btn_create_playlist")
                    }}</b-button
                  >
                </label>
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.add_one_more_playlist"
                    )
                  }}<b-link
                    target="_blank"
                    href="https://support.google.com/youtube/answer/72431?hl=en#zippy=%2Cimage-size-resolution"
                    >{{ $t("Pages.Studio.Title.learn_more") }}</b-link
                  >
                </p>
                <v-select
                  id="vue-playlist"
                  v-model="formCreate.playlistsSelect"
                  :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                  :options="playlists"
                />
              </b-form-group>
              <b-form-group label-for="vue-select">
                <label for="selfDeclaredMadeForKids" style="font-size: 16px">{{
                  this.$t("Pages.Studio.Label.made_for_kids")
                }}</label>
                <p class="text-muted">
                  {{ $t("Pages.Studio.Title.COPPA")
                  }}<b-link
                    target="_blank"
                    href="https://support.google.com/youtube/answer/9528076?hl=en"
                    >{{
                      $t(
                        "Pages.Studio.Title.what_content_kids"
                      )
                    }}</b-link
                  >
                </p>
                <b-form-radio
                  v-model="formCreate.selfDeclaredMadeForKids"
                  plain
                  name="selfDeclaredMadeForKids"
                  value="true"
                  >{{
                    this.$t(
                      "Pages.Studio.Title.self_made_kids"
                    )
                  }}</b-form-radio
                >
                <b-form-radio
                  v-model="formCreate.selfDeclaredMadeForKids"
                  plain
                  name="selfDeclaredMadeForKids"
                  value="false"
                  >{{
                    this.$t("Pages.Studio.Title.self_made_kids_no")
                  }}</b-form-radio
                >
              </b-form-group>
              <div style="margin-top: 20px; margin-bottom: 10px">
                <b-link v-if="showAdvanced"
                  ><p class="text-info pointer-event" @click="switchAdvanced">
                    {{ $t("Pages.Studio.Title.show_less") }}
                  </p></b-link
                >
                <b-link v-else
                  ><p class="text-info pointer-event" @click="switchAdvanced">
                    {{ $t("Pages.Studio.Title.show_more_info") }}
                  </p></b-link
                >
              </div>
              <b-form-group v-if="showAdvanced">
                <label for="tags" style="font-size: 16px"
                  >{{ this.$t("Pages.Studio.Label.Tags") }}:
                  <feather-icon
                    :class="seo.hashtag ? 'text-info mx-1' : 'mx-1'"
                    v-b-tooltip.hover.top="
                      $t(
                        'Pages.Studio.Title.least_one_tag'
                      )
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />
                  <feather-icon
                    :class="seo.charactersTags ? 'text-info mx-1' : 'mx-1'"
                    v-b-tooltip.hover.top="
                      $t(
                        'Pages.Studio.Title.number_of_tag_between'
                      )
                    "
                    icon="AlertCircleIcon"
                    size="16"
                  />
                </label>
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.tags_can_be_usefull"
                    )
                  }}<b-link
                    target="_blank"
                    href="https://support.google.com/youtube/answer/146402?hl=en"
                    >{{ $t("Pages.Studio.Title.know_more") }}</b-link
                  >
                </p>
                <b-form-tags
                  id="tags"
                  maxlength="500"
                  v-model="formCreate.tags"
                  :placeholder="$t('Base.Placeholder.Tags')"
                  :add-button-text="$t('Pages.Studio.Button.add_tag')"
                  :tag-validator="tagValidator"
                  separator=","
                  :class="[
                    seo.hashtag || seo.charactersTags ? 'border-warning' : '',
                  ]"
                />
                <keyword-suggestion
                  :suggestions="tagSuggestions"
                  :changeSelectedHashtag="changeSelectedTag"
                />
                <small class="mt-2">
                  {{ $t("Pages.Studio.Title.Characters") }}: 500 /
                  {{ formCreate.countCharactersTags }}
                </small>
                <p v-if="seo.hashtag" class="text-info d-block">
                  {{
                    $t(
                      "Pages.Studio.Title.least_one_tag"
                    )
                  }}
                </p>
                <p v-if="seo.charactersTags" class="text-info d-block">
                  {{
                    $t(
                      "Pages.Studio.Title.number_of_tag_between"
                    )
                  }}
                </p>
              </b-form-group>
              <b-form-group v-if="showAdvanced" label-for="vue-select">
                <label for="defaultAudioLanguage" style="font-size: 16px"
                  >{{
                    this.$t("Pages.Studio.Label.default_audio_lang")
                  }}:</label
                >
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.select_lang_subtitle"
                    )
                  }}
                </p>
                <v-select
                  id="vue-select"
                  v-model="formCreate.defaultAudioLanguage"
                  :dir="$i18n.locale=='fa' ? 'rtl' : 'ltr'"
                  :options="AudioLanguage"
                />
              </b-form-group>
              <b-form-group
                v-if="showAdvanced"
                label-for="vue-select"
                class="mb-5 pb-5"
              >
                <label for="category" style="font-size: 16px"
                  >{{ this.$t("Pages.Studio.Label.Category") }}:</label
                >
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.add_vid_category"
                    )
                  }}
                </p>
                <v-select
                  id="vue-select"
                  v-model="formCreate.category"
                  :dir="$i18n.locale=='fa' ? 'rtl' : 'ltr'"
                  :options="Category"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              sm="12"
              offset="1"
              class="text-center mx-auto order-0 order-md-1"
            >
              <div class="w-100 mt-2">
                <div
                  class="d-flex flex-column text-center mx-auto justify-content-center align-items-center"
                  width="100%"
                  style="width: 100%; height: 170px; background: #000"
                >
                  <div v-if="progress != 100" class="text-center right mt-2">
                    <p>{{ $t("Pages.Studio.Title.vid_uploading") }}</p>
                    <h4 class="text-primary">{{ progress }}%</h4>
                  </div>
                  <p v-else class="text-right right mt-2">
                    {{ $t("Pages.Studio.Title.success_vid_upload") }}
                  </p>
                </div>
                <div
                  class="text-left px-1 pt-1"
                  style="background-color: rgb(50 48 48)"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p>{{ $t("Pages.Studio.Title.vid_link") }}</p>
                    <a href="">youtube.com</a>
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <p>{{ $t("Pages.Studio.Title.file_name") }}</p>
                    <p class="text-left">{{ formCreate.name_file }}</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="EyeIcon" />
            <span>{{ $t("Pages.Studio.Title.second_step_pub") }}</span>
          </template>
          <b-row cols="12" class="mt-2">
            <b-col md="7" sm="12" class="mx-auto order-1 order-md-0">
              <b-form-group
                label-for="vue-select"
                class="border border-white p-2"
              >
                <label for="category" style="font-size: 16px"
                  >{{ this.$t("Pages.Studio.Label.privacy_status") }}:</label
                >
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.add_vid_category"
                    )
                  }}
                </p>
                <b-form-radio
                  v-model="formCreate.privacyStatus"
                  plain
                  name="privacyStatus"
                  value="private"
                >
                  <h6>{{ this.$t("Pages.Studio.Title.Private") }}</h6>
                  <p class="text-muted">
                    {{
                      $t(
                        "Pages.Studio.Title.only_you_choose"
                      )
                    }}
                  </p>
                </b-form-radio>
                <b-form-radio
                  v-model="formCreate.privacyStatus"
                  plain
                  name="privacyStatus"
                  value="unlisted"
                >
                  <h6>{{ this.$t("Pages.Studio.Title.Unlisted") }}</h6>
                  <p class="text-muted">
                    {{
                      $t(
                        "Pages.Studio.Title.anyone_has_link"
                      )
                    }}
                  </p>
                </b-form-radio>
                <b-form-radio
                  v-model="formCreate.privacyStatus"
                  plain
                  name="privacyStatus"
                  value="public"
                >
                  <h6>{{ this.$t("Pages.Studio.Title.Public") }}</h6>
                  <p class="text-muted">
                    {{ $t("Pages.Studio.Title.everyone_can_see") }}
                  </p>
                </b-form-radio>
              </b-form-group>
              <b-form-group
                label-for="vue-select"
                class="mt-1 border border-white p-2"
              >
                <label for="category" style="font-size: 16px"
                  >{{ this.$t("Pages.Studio.Label.Schedule") }}:</label
                >
                <p class="text-muted">
                  {{
                    $t(
                      "Pages.Studio.Title.choose_date_to_public"
                    )
                  }}
                </p>
                <flat-pickr
                  v-model="formCreate.schedule"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    minDate: 'today',
                    dateFormat: 'Y-m-d H:i',
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="3"
              sm="12"
              offset="1"
              class="text-center mx-auto order-0 order-md-1"
            >
              <div class="w-100 mt-2">
                <div
                  class="d-flex flex-column text-center mx-auto justify-content-center align-items-center"
                  width="100%"
                  style="width: 100%; height: 170px; background: #000"
                >
                  <div v-if="progress != 100" class="text-center right mt-2">
                    <p>{{ $t("Pages.Studio.Title.vid_uploading") }}</p>
                    <h4 class="text-primary">{{ progress }}%</h4>
                  </div>
                  <p v-else class="text-right right mt-2">
                    {{ $t("Pages.Studio.Title.success_vid_upload") }}
                  </p>
                </div>
                <div
                  class="text-left px-1 pt-1"
                  style="background-color: rgb(50 48 48)"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <p>{{ $t("Pages.Studio.Title.vid_link") }}</p>
                    <a href="">youtube.com</a>
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <p>{{ $t("Pages.Studio.Title.file_name") }}</p>
                    <p class="text-left">{{ formCreate.name_file }}</p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <div
        v-if="!select_video_step"
        slot="modal-footer"
        class="w-100 d-flex justify-content-between align-items-center"
      >
        <div class="">
          <p v-if="progress != 100">
            {{ $t("Pages.Studio.Title.Uploaded") }} <span>{{ progress }}%</span>
          </p>
          <p v-else>{{ $t("Pages.Studio.Title.upload_completed") }}</p>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <b-button
            v-if="tabIndex == 0"
            variant="primary"
            class="px-3"
            @click="goToNextPage"
            >{{ $t("Pages.Studio.Button.Next") }}</b-button
          >
          <b-button
            v-if="tabIndex == 1"
            variant="primary"
            class="px-3"
            @click="publishVideoInYoutube"
            >{{ $t("Pages.Studio.Button.Upload") }}</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BBadge,
  BMedia,
  BButton,
  BImg,
  BFormFile,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BTooltip,
  BRow,
  BCol,
  BFormTextarea,
  BOverlay,
  VBTooltip,
  BModal,
  BFormRadio,
  BFormTags,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { v4 as uuidv4 } from "uuid";
import KeywordSuggestion from "./KeywordSuggestion.vue";
import BlacklistWordNotification from "./BlacklistWordNotification.vue";
import TextGenerator from "./TextGenerator.vue";
import UploadProfile from "./UploadProfile.vue";
import uploadStaticIcon from "@/assets/images/icons/upload-static.png";

export default {
  name: "upload-video",
  components: {
    BLink,
    BBadge,
    BMedia,
    BButton,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BImg,
    BFormFile,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BTooltip,
    BRow,
    BCol,
    BFormTextarea,
    BOverlay,
    VBTooltip,
    vSelect,
    BModal,
    BFormRadio,
    BFormTags,
    flatPickr,
    KeywordSuggestion,
    BlacklistWordNotification,
    TextGenerator,
    UploadProfile,
  },
  data() {
    return {
      tabIndex: 0,
      select_video_step: true,
      showAdvanced: false,
      uploadStaticIcon:uploadStaticIcon,
      file: null,
      file_prew: null,
      formCreate: {
        name_file: "",
        title: "",
        description: "",
        thumbnails: null,
        thumbnails_prew: null,
        defaultAudioLanguage: { label: "فارسی", value: "fa-IR" },
        category: { label: "Film & Animation", value: 1 },
        privacyStatus: "private",
        playlistsSelect: {
          label: this.$t("Pages.Studio.Title.not_playlist"),
          value: null,
        },
        btn: this.$t("Pages.Studio.Button.btn_create_vid"),
        titleForm: this.$t("Pages.Studio.Title.title_model_create_vid"),
        tags: [],
        countCharactersTags: 0,
        selfDeclaredMadeForKids: false,
        schedule: "",
      },
      category: { label: "Film & Animation", value: 1 },
      Category: [],
      AudioLanguage: [],
      seo: {
        title: false,
        tagDescription: false,
        socialDescription: false,
        titleDescription: false,
        hashtag: false,
        charactersTags: false,
      },
      playlists: [],
      formCreatePlaylist: {
        title: "",
        description: "",
        privacyStatus: "public",
        btn: this.$t("Pages.Studio.Button.btn_create_playlist"),
        titleForm: this.$t("Pages.Studio.Title.title_model_create"),
      },
      uuid: "",
      filename_uploaded: null,
      uploaded: 0,
      failedUpload: 0,
      publishVideo: false,
      pendingPersian: 0,
      suggestions: [],
      option:[],
      tagSuggestions: [],
      currentHashtag: "",
      click_button_upload_video: false,
      titleBlacklistWords: [],
      descriptionBlacklistWords: [],
      textGeneratorModalType: "",
    };
  },
  props: ["channel_id", "user_id"],
  computed: {
    progress() {
      return this.file
        ? Math.floor((this.uploaded * 100) / this.file.size) -
            (Math.floor((this.uploaded * 100) / this.file.size) > 5
              ? this.pendingPersian
              : 0)
        : 0;
    },
    formData() {
      let formData = new FormData();
      formData.set("video", this.file, `${this.file.name}`);
      formData.set("uuid", this.uuid);
      formData.set("channel_id", this.channel_id);
      formData.set("user_id", this.user_id);
      formData.set(
        "name_file",
        this.uuid +
          "." +
          this.file.name.substring(this.file.name.lastIndexOf(".") + 1)
      );
      return formData;
    },
    configPersian() {
      return {
        method: "POST",
        data: this.formData,
        url: `http://upload.master.tube/api/upload`,
        timeout: 99999999999999999,
        headers: {
          Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
          "Content-Type": "application/octet-stream",
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded <= this.file.size) {
            this.show = true;
          } else {
            this.$toast.clear();
          }
          this.uploaded = progressEvent.loaded;
        },
      };
    },
    configMainServer() {
      return {
        method: "POST",
        data: this.formData,
        url: `https://api.master.tube/api/channel/studio/videos/upload-file`,
        timeout: 99999999999999999,
        headers: {
          Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
          "Content-Type": "application/octet-stream",
        },
        maxRedirects: 0,
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded <= this.file.size) {
            this.show = true;
          } else {
            this.$toast.clear();
          }
          this.uploaded = progressEvent.loaded;
        },
      };
    },
    textToastUplaod() {
      return (
        this.$t("Pages.Studio.Title.your_vid_uploading") +
        `%${this.progress}`
      );
    },
    hashtagRegex() {
      return /\#(?!\,)[\S]+/g;
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mounted() {
    this.getStaticDataInForm();
    document.addEventListener("keyup", this.tagChangesHandler);
    document.addEventListener("click", this.closeKeywordSuggestion);
  },
  methods: {
    goToNextPage() {
      this.tabIndex = 1;
    },
    updateKeywordSuggestionsStyle() {
      const textarea = document.getElementById("description");
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const value = textarea.value;
      const rowsBefore = value.slice(0, start).match(/\n/g) || [];
      const rowsAfter = value.slice(end).match(/\n/g) || [];
      const numRows = rowsBefore.length + 1;
      const rowStart = value.lastIndexOf("\n", start - 1) + 1;
      const rowEnd = value.indexOf("\n", end);
      const rowText = value.slice(rowStart, rowEnd !== -1 ? rowEnd : undefined);
      const span = document.createElement("span");
      span.textContent = rowText;
      span.style.whiteSpace = "nowrap";
      document.body.appendChild(span);
      const width = span.offsetWidth;
      document.body.removeChild(span);
      const keywordSuggestions = document.getElementById("keywordSuggestions");
      if (keywordSuggestions) {
        keywordSuggestions.style.top = `calc(${numRows * 25}px + 2.9rem)`;
        keywordSuggestions.style.right = `calc(${width}px + 1rem) `;
      }
    },
    switchAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
    onChangeVideo(e) {
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    createFile(file) {
      this.getListPlayList(this.channel_id);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.file_prew = reader.result;
      };
      this.file = file;
      setTimeout(() => {
        this.select_video_step = false;
        this.formCreate.name_file = file.name;
        this.formCreate.title = file.name.split(".")[0];
        this.uuid = uuidv4();
        // this.$http({
        //     method: "GET",
        //     url:  `http://upload.master.tube/api/upload`,
        //     timeout: 10000,
        // }).then(res=>{
        //     this.uploadPersian();
        // }).catch(err=>{
        //     this.uploadMainServer();
        // })
        this.uploadMainServer();
      }, 5000);
    },
    selectFile() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (_) => {
        let files = Array.from(input.files);
        this.createFile(files[0]);
      };
      input.click();
    },
    onChangeThumbnail(e) {
      e.preventDefault();
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFileThumbnail(files[0]);
    },
    createFileThumbnail(file) {
      var ext = file.name.substring(file.name.lastIndexOf(".") + 1);
      let formats = ["png", "jpg", "jpeg"];
      if (!formats.includes(ext)) {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Pages.Studio.Title.format_not_valid"),
          "danger"
        );
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formCreate.thumbnails_prew = reader.result;
        };
        this.formCreate.thumbnails = file;
      }
    },
    selectThumbnail() {
      let input = document.createElement("input");
      input.type = "file";
      input.onchange = (_) => {
        let files = Array.from(input.files);
        this.createFileThumbnail(files[0]);
      };
      input.click();
      //
    },
    showProssessInPage() {
      if (this.file) {
        this.$toast.success(this.textToastUplaod, {
          position: "bottom-left",
          timeout: 999999999999,
          closeOnClick: false,
          rtl: true,
          closeButton: false,
          pauseOnHover: false,
          hideProgressBar: false,
          draggable: false,
          showCloseButtonOnHover: false,
          onClick: () => {
            this.openModal();
            this.$toast.clear();
          },
        });
      }
    },
    openModal() {
      this.$refs["modal-upload-video"].show();
      this.$toast.clear();
    },
    closeModal() {
      this.$refs["modal-upload-video"].hide();
      this.showProssessInPage();
    },
    checkSeoTitle() {
      if (
        this.formCreate.title.length > 70 &&
        this.formCreate.title.length < 100
      ) {
        this.seo.title = false;
      } else {
        this.seo.title = true;
      }
    },
    checkSeoDescription() {
      if (this.formCreate.description.match(/#/) || [].length >= 1) {
        this.seo.tagDescription = false;
      } else {
        this.seo.tagDescription = true;
      }
      if (this.formCreate.description.match(/http/) || [].length >= 1) {
        this.seo.socialDescription = false;
      } else {
        this.seo.socialDescription = true;
      }
      if (this.formCreate.description.includes(this.formCreate.title)) {
        this.seo.titleDescription = false;
      } else {
        this.seo.titleDescription = true;
      }
    },
    tagValidator(tag) {
      this.formCreate.countCharactersTags =
        this.formCreate.tags.toString().length;
      if (
        this.formCreate.countCharactersTags <= 400 ||
        this.formCreate.countCharactersTags >= 500
      ) {
        this.seo.charactersTags = true;
      } else {
        this.seo.charactersTags = false;
      }
      if (
        this.formCreate.countCharactersTags < 500 &&
        this.formCreate.countCharactersTags + tag.length < 500
      ) {
        return tag;
      }
    },
    getListPlayList(channel_id) {
      this.$http
        .get(
          `/api/channel/studio/playlists/update-list?channel_id=${channel_id}`
        )
        .then((response) => {
          if (response.data.status || response.data.status == "true") {
            this.$http
              .get(`/api/channel/studio/playlists/list-in-video-page`, {
                params: {
                  channel_id: channel_id,
                },
              })
              .then((response) => {
                this.playlists = response.data;
              });
          }
        });
    },
    CreatePlaylist() {
      if (this.formCreatePlaylist.title != "") {
        this.$http
          .get(`/api/channel/studio/checkPermission`, {
            headers: {
              Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
            },
            params: {
              channel_id: this.$route.params.channel_id,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$http
                .post(
                  `/api/channel/studio/playlists/create`,
                  {
                    channel_id: this.$route.params.channel_id,
                    title: this.formCreatePlaylist.title,
                    description: this.formCreatePlaylist.description,
                    privacy_status: this.formCreatePlaylist.privacyStatus,
                  },
                  {
                    headers: {
                      Authorization:
                        "Bearer " + this.$VueCookies.get("mastertube"),
                    },
                  }
                )
                .then((response) => {
                  this.makeToast(
                    this.$t("Base.Alert.Successful"),
                    this.$t("Pages.Studio.Alert.success_create_playlist"),
                    "success"
                  );
                  this.formCreatePlaylist.title = "";
                  this.formCreatePlaylist.description = "";
                  this.getListPlayList(this.channel_id);
                });
            } else {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t(
                  "Pages.Studio.Title.ch_access_incomplet"
                ),
                "danger"
              );
            }
          });
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Pages.Studio.Alert.care_fill_info"),
          "danger"
        );
      }
    },
    getStaticDataInForm() {
      this.$http
        .get(`/api/channel/studio/videos/get-static-data-form`, {
          headers: {
            Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
          },
        })
        .then((response) => {
          let lang = [];
          response.data.languages.map((item) => {
            lang.push({ label: item.name, value: item.abbreviation });
          });
          this.AudioLanguage = lang;

          let category = [];
          response.data.categories.map((item) => {
            category.push({ label: item.name, value: item.category_id });
          });
          this.Category = category;
        });
    },
    uploadPersian() {
      this.pendingPersian = 1;
      this.$http(this.configPersian)
        .then((response) => {
          if (response.data) {
            this.pendingPersian = 0;
            this.filename_uploaded =
              this.uuid +
              "." +
              this.file.name.substring(this.file.name.lastIndexOf(".") + 1);
            if (this.publishVideo) {
              setTimeout(() => {
                this.submitDetailsVideoAfterupload();
              }, 5000);
            }
          }
        })
        .catch(() => {
          this.pendingPersian = 0;
          if (this.failedUpload >= 2) {
            this.failedUpload += 1;
            this.uploadPersian();
          } else {
            this.uploadMainServer();
          }
        });
    },
    uploadMainServer() {
      this.$http(this.configMainServer)
        .then((response) => {
          if (response.data.filename) {
            this.filename_uploaded = response.data.filename;
            if (this.publishVideo) {
              setTimeout(() => {
                this.submitDetailsVideoAfterupload();
              }, 5000);
            }
          }
        })
        .catch(() => {
          if (this.failedUpload < 7) {
            this.failedUpload += 1;
            this.uploadMainServer();
          } else {
            this.$swal({
              title: this.$t("Base.Alert.Error"),
              text: this.$t(
                "Base.Alert.no_connect_server"
              ),
              icon: "warning",
              confirmButtonText: this.$t("Base.Alert.Understand"),
            });
          }
        });
    },
    publishVideoInYoutube() {
      if (this.filename_uploaded) {
        this.submitDetailsVideoAfterupload();
      } else {
        this.publishVideo = true;
        this.$swal({
          title: this.$t("Base.Alert.Successful"),
          text: this.$t(
            "Pages.Studio.Alert.publish_req_registered"
          ),
          icon: "success",
          confirmButtonText: this.$t("Base.Alert.Understand"),
        });
      }
    },
    submitDetailsVideoAfterupload() {
      if (!this.click_button_upload_video) {
        this.click_button_upload_video = true;
        let data = new FormData();
        data.append("channel_id", this.$route.params.channel_id);
        data.append("title", this.formCreate.title);
        data.append(
          "description",
          this.formCreate.description != "" ? this.formCreate.description : " "
        );
        data.append("video", this.filename_uploaded);
        if (this.formCreate.thumbnails_prew) {
          data.append("thumbnail", this.formCreate.thumbnails);
        }
        data.append("tags", this.formCreate.tags);
        data.append("schedule", this.formCreate.schedule);
        data.append(
          "default_audio_language",
          this.formCreate.defaultAudioLanguage.value
        );
        data.append("category_id", this.formCreate.category.value);
        data.append("playlist_id", this.formCreate.playlistsSelect.value);
        data.append(
          "self_declared_made_for_kids",
          this.formCreate.selfDeclaredMadeForKids == "true" ? true : false
        );
        data.append("privacy_status", this.formCreate.privacyStatus);
        data.append("file_name", this.formCreate.name_file);

        this.$http
          .post(`/api/channel/studio/videos/create`, data, {
            headers: {
              Authorization: "Bearer " + this.$VueCookies.get("mastertube"),
              "content-type": "multipart/form-data",
            },
            timeout: 9999999,
          })
          .then((response) => {
            if (response.data.status) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Pages.Studio.Alert.success_create_vid"),
                "success"
              );
              this.formCreate.prew = "";
              this.formCreate.thumbnails_prew = "";
              this.$root.$emit("updateCoin", true);
              this.$router.push({
                name: "videos",
                params: {
                  channel_id: this.$route.params.channel_id,
                },
              });
              localStorage.removeItem("data");
              this.$swal({
                title: this.$t("Base.Alert.Successful"),
                text: this.$t(
                  "Pages.Studio.Alert.success_vid_upload"
                ),
                icon: "success",
                confirmButtonText: this.$t("Base.Alert.Understand"),
              })
                .then((res) => {
                  location.reload();
                })
                .catch((err) => {
                  location.reload();
                });
              setTimeout(() => {
                location.reload();
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Pages.Studio.Alert.success_create_vid"),
                "success"
              );
              this.formCreate.prew = "";
              this.formCreate.thumbnails_prew = "";
              this.$root.$emit("updateCoin", true);
              this.$router.push({
                name: "videos",
                params: {
                  channel_id: this.$route.params.channel_id,
                },
              });
              localStorage.removeItem("data");
              this.$swal({
                title: this.$t("Base.Alert.Successful"),
                text: this.$t(
                  "Pages.Studio.Alert.success_upload_vid"
                ),
                icon: "success",
                confirmButtonText: this.$t("Base.Alert.Understand"),
              })
                .then((res) => {
                  location.reload();
                })
                .catch((err) => {
                  location.reload();
                });
              setTimeout(() => {
                location.reload();
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response.status === 500) {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Pages.Studio.Alert.error_while_confirm"),
                "danger"
              );
            }
            if (error.response.status === 402) {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Pages.Studio.Alert.error_while_confirm"),
                "danger"
              );
            }
            if (error.response.status === 402) {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t(
                  "Pages.Studio.Alert.not_have_ability"
                ),
                "danger"
              );
            }
          });
      }
    },
    changeSelectedHashtag(hashtag) {
      this.formCreate.description = this.formCreate.description.replace(
        this.currentHashtag,
        "#" + hashtag
      );
      this.suggestions = [];
    },
    tagChangesHandler(event) {
      if (event.target.id !== "tags___input__") return false;
      if (event.target.value === "") {
        this.tagSuggestions = [];
        return false;
      }

      this.$http
        .post("/api/tools/keyword-suggestion", { hashtag: event.target.value })
        .then((response) => {
          if (response.data.status) {
            this.tagSuggestions = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeSelectedTag(tag) {
      this.formCreate.tags.push(tag);
      const tags = document.getElementById("tags___input__");
      tags.setAttribute("value", "");
      tags.value = "";
      this.tagSuggestions = [];
    },
    closeKeywordSuggestion() {
      if (this.suggestions.length > 0) {
        this.suggestions = [];
      }
      if (this.tagSuggestions.length > 0) {
        this.tagSuggestions = [];
      }
    },
  },
  watch: {
    "formCreate.description"(newValue, oldValue) {
      const oldHashtags = [];
      const newHashtags = [];

      for (const match of newValue.matchAll(this.hashtagRegex)) {
        newHashtags.push(match[0]);
      }
      for (const match of oldValue.matchAll(this.hashtagRegex)) {
        oldHashtags.push(match[0]);
      }

      const diff = newHashtags.filter((x) => !oldHashtags.includes(x));

      if (diff.length > 0) {
        this.currentHashtag = diff[0];
        this.$http
          .post("/api/tools/keyword-suggestion", { hashtag: diff[0] })
          .then((response) => {
            if (response.data.status) {
              this.suggestions = response.data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.currentHashtag = "";
        this.suggestions = [];
      }

      this.$http
        .post("/api/channel/studio/check-blacklist-word", {
          text: newValue,
        })
        .then((response) => {
          if (response.data.status) {
            this.descriptionBlacklistWords = response.data.data;
          } else {
            this.descriptionBlacklistWords = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    "formCreate.title"(newTitle, oldTitle) {
      if (newTitle.trim() !== oldTitle.trim()) {
        this.$http
          .post("/api/channel/studio/check-blacklist-word", {
            text: newTitle,
          })
          .then((response) => {
            if (response.data.status) {
              this.titleBlacklistWords = response.data.data;
            } else {
              this.titleBlacklistWords = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.9 !important;
}

.Vue-Toastification__toast.Vue-Toastification__toast--success.bottom-left.Vue-Toastification__toast--rtl {
  background: rgb(199 103 25);
  color: #d8d6de;
}

.modal .modal-huge {
  max-width: 90%;
  width: 90%;
}

@media (min-width: 768px) {
  .modal .modal-huge {
    max-width: 60%;
    width: 60%;
  }
}

input[type="file"] {
  border: none;
}

[dir] .custom-file-label {
  padding: 0 !important;
  background-color: inherit !important;
  border: 0px solid #d8d6de !important;
}

.custom-file-label::after {
  background-color: inherit !important;
  border-right: none !important;
  padding: 0 !important;
}

[dir="rtl"] .custom-file-label {
  left: inherit !important;
  right: inherit !important;
}

.text-generator-button {
  right: 0;
  top: -5px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

[dir] .b-form-tag {
  background-color: #82868b;
}
</style>
